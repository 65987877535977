import { RiCloseCircleFill } from 'react-icons/ri';
// import modalImage from '../../Assets/Images/modal-img.png';
import { BASE_URL } from '../../Utilities/APIs';
import './Modal.css';

function Modal({

    selectedDirector,
    setShowModal

}) {
    return (
        // <div className={`modal ${state ? 'show' : ''}`}>
        <div className={`modal`}>
            <div className="modal_backdrop"></div>
            <div className="modal_dialog modal_dialog_centered">
                <div className="modal_content">
                    <div className="image_wrapper">
                        <img src={BASE_URL + selectedDirector?.image.slice(6, selectedDirector?.image.length)} alt="thumb" />
                    </div>
                    <div className="content_wrapper">
                        <RiCloseCircleFill role='button' type='button' onClick={() => setShowModal(false)} />
                        <h1>{selectedDirector?.name}</h1>
                        <h6>{selectedDirector?.designation}</h6>
                        <div className="text_wrapper">
                            {/* <p className='text'>Sandeep Jain, is a Civil Engineer and has done a Certificate course in Management (Leading & Managing) from IIM Kolkata.  He joined Indian Oil Corporation Ltd, in 1987 and has served around 35 years in the Industry.  He was posted at various offices in Punjab, Haryana, Maharashtra, Bihar, and Jharkhand in early part of his career. In the later part of his career he was heading the Punjab State Office (comprising the territories of Punjab, HP, J & K and Chandigarh). He has been a Sales Officer & Divisional Retail Sales Head of IOC in Punjab & Haryana for 11 years.</p>
                            <p className='text'>Presently as the Executive Director (Business Development), he is heading the Business Development Group looking after Downstream Business of IndianOil in Overseas Territory. In addition to being the Chairman of this JV company in Bangladesh, he has also led the formation of Bitumen JV with TOTAL & Retail JV in Saudi Arabia. He has also enhanced Geo-Strategic reach by opening of Offices/Subsidiaries in Nepal & Russia, Exports to Bangladesh, Myanmar, Sri Lanka & Mauritius.</p> */}
                            <p className='text'>
                                {
                                    selectedDirector?.description.includes('<br />')
                                        ?
                                        <>
                                            {selectedDirector?.description.split('<br />')[0]}
                                            <br />
                                            <br />
                                            {selectedDirector?.description.split('<br />')[1]}
                                            {selectedDirector?.description.split('<br />')[2] ?

                                                <>
                                                    <br />
                                                    <br />
                                                    {selectedDirector?.description.split('<br />')[2]}
                                                </>
                                                :
                                                ''
                                            }

                                        </>
                                        :
                                        selectedDirector?.description
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal