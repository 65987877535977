import { useState } from 'react';

import './App.css';

import Banner from './Components/Banner/Banner';
// import BannerSlider from './Components/BannerSlider/BannerSlider';
import BoardDirectors from './Components/BoardDirectors/BoardDirectors';
import CompanyOverview from './Components/CompanyOverview/CompanyOverview';
import ContactUs from './Components/ContactUs/ContactUs';
import Footer from './Components/Footer/Footer';
import ManagementExecutive from './Components/ManagementExecutive/ManagementExecutive';
import Navbar from './Components/Navbar/Navbar';
import SMNavigation from './Components/Navbar/SMNavigation';

function App() {
  const [navOpen, setNavOpen] = useState(false);
  const navToggle = () => {
    setNavOpen(prevState => !prevState);
  }

  return (
    <main>
      <Navbar navToggle={navToggle} />
      <SMNavigation navOpen={navOpen} navToggle={navToggle} />
      <Banner />
      {/* <BannerSlider /> */}
      <CompanyOverview />
      <BoardDirectors />
      <ManagementExecutive />
      <ContactUs />
      <Footer />
    </main>
  );
}

export default App;
