import { BASE_URL } from "../../Utilities/APIs"

function MgmtITCard({imgSrc, name, description, designation}) {
  return (
    <div className="grid_container">
        <div className="grid_item">
            <img src={BASE_URL + imgSrc.slice(6,imgSrc.length)} alt="thumb" />
        </div>
        <div className="grid_item">
            <h5>{name}</h5>
            <h6>{designation}</h6>
            <p className="text">{description}</p>
        </div>
    </div>
  )
}

export default MgmtITCard