import { CloseIcon } from '../Elements/Icons';
import Logo from './Logo';
import Navigation from './Navigation';

import './SMNavigation.css';

function SMNavigation({ navOpen, navToggle }) {
  return (
    <div className={`smnavigation_wrapper ${navOpen ? 'show' : ''}`}>
      <div className="close_box">
        <button type='button' onClick={navToggle}>
          <CloseIcon />
        </button>
      </div>
      <div className="logo_wrapper">
        <Logo />
      </div>
      <Navigation navToggle={navToggle} />
    </div>
  )
}

export default SMNavigation