import axios from 'axios';
import { useState, useEffect } from 'react';
// import COImage from '../../Assets/Images/co-image.png';
import { BASE_URL, COMPANY_OVERVIEW_API } from '../../Utilities/APIs';
// import Image from '../Elements/Image';

import './CompanyOverview.css';

function CompanyOverview() {

  const [companyOverview, setCompanyOverview] = useState(null);
  // useEffect(() => {
  //   const fetchCompanyOverview = async () => {
  //     const { data } = await axios.get(COMPANY_OVERVIEW_API);
  //     setCompanyOverview(data[0])
  //   }
  //   fetchCompanyOverview();
  // }, [])

  useEffect(() => {
    async function fetchCompanyOverview() {
      const { data } = await axios.get(COMPANY_OVERVIEW_API);
      setCompanyOverview(data[0])
    }
    fetchCompanyOverview()
  }, [])

  return (
    <section className="company_overview" id='overview'>
      <div className="container">
        <div className="grid_container">
          <div className="grid_item">
            {
              companyOverview &&
              <>
              <h1 className="sc_title">
                {companyOverview.title.split(' ')[0] + ' '} 
                <span>{companyOverview.title.split(' ')[1]}</span>
              </h1>
              <p className="text">
                {/* In June 2020, IOC Middle East FZE, Dubai, a wholly-owned subsidiary of Indian Oil Corporation, India’s largest refiner and marketer of petroleum products, and RR Holdings Ltd., Ras Al Khaimah, UAE, the holding company of Beximco LPG of Bangladesh, signed an agreement for the formation of a 50:50 Joint Venture Company (JVC) for LPG business in Bangladesh. The JVC has been named Beximco IOC Petroleum & Energy Ltd (BIPEL) with an incorporated address of Plot No.24 (New) 17 (Old), Bir Uttam M. A. Rob Sarak, Road No. 2, Dhanmondi, Dhaka-1205, Bangladesh.
                <br />
                <br />
                The JVC aims to explore and undertake (not limited to) the development of import and storage terminals and related infrastructure, sourcing and marketing of LPG/Auto LPG in Bangladesh, supply of bulk LPG to North East India, sourcing and supply of LNG, import infrastructure and supply of petrochemicals, and blending infrastructure and marketing of lubricants. */}
                {companyOverview.description.split('<br />')[0]}
                <br />
                <br />
                {companyOverview.description.split('<br />')[1]}
              </p>
              </>
            }
          </div>
          <div className="grid_item">
            <img 
              src={BASE_URL + companyOverview?.image.slice(6,companyOverview?.image.length)} 
              alt="thumb" 
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CompanyOverview