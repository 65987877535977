import React from 'react'

export const MenuIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="30" viewBox="0 0 35 30" fill="none">
            <path d="M5.83325 8.75C5.83325 8.05964 6.3929 7.5 7.08325 7.5H27.9166C28.6069 7.5 29.1666 8.05964 29.1666 8.75C29.1666 9.44036 28.6069 10 27.9166 10H7.08325C6.3929 10 5.83325 9.44036 5.83325 8.75ZM9.33325 15.1179C9.33325 14.4982 9.83145 13.9937 10.4511 13.9859L27.9088 13.7659C28.6009 13.7571 29.1666 14.3158 29.1666 15.0079C29.1666 15.6939 28.6105 16.25 27.9245 16.25H10.4653C9.84011 16.25 9.33325 15.7431 9.33325 15.1179ZM13.9999 21.25C13.9999 20.5596 14.5596 20 15.2499 20H27.9166C28.6069 20 29.1666 20.5596 29.1666 21.25C29.1666 21.9404 28.6069 22.5 27.9166 22.5H15.2499C14.5596 22.5 13.9999 21.9404 13.9999 21.25Z" fill="#393939" />
        </svg>
    )
}

export const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <path d="M4.57598 2.20128L13 10.6253L21.3893 2.23594C21.5363 2.07945 21.7135 1.95427 21.91 1.86789C22.1066 1.78151 22.3186 1.73573 22.5333 1.73328C22.993 1.73328 23.4339 1.91589 23.759 2.24096C24.084 2.56602 24.2667 3.0069 24.2667 3.46661C24.2707 3.67912 24.2313 3.89021 24.1508 4.08694C24.0703 4.28366 23.9505 4.46186 23.7987 4.61061L15.3227 12.9999L23.7987 21.4759C24.0843 21.7554 24.2519 22.1339 24.2667 22.5333C24.2667 22.993 24.084 23.4339 23.759 23.7589C23.4339 24.084 22.993 24.2666 22.5333 24.2666C22.3124 24.2758 22.092 24.2389 21.8861 24.1583C21.6802 24.0778 21.4933 23.9553 21.3373 23.7986L13 15.3746L4.59332 23.7813C4.44686 23.9325 4.27189 24.0533 4.07852 24.1366C3.88514 24.2199 3.67719 24.2641 3.46665 24.2666C3.00694 24.2666 2.56606 24.084 2.241 23.7589C1.91593 23.4339 1.73331 22.993 1.73331 22.5333C1.72927 22.3208 1.7687 22.1097 1.84918 21.9129C1.92966 21.7162 2.04949 21.538 2.20131 21.3893L10.6773 12.9999L2.20131 4.52394C1.91563 4.24446 1.74812 3.86599 1.73331 3.46661C1.73331 3.0069 1.91593 2.56602 2.241 2.24096C2.56606 1.91589 3.00694 1.73328 3.46665 1.73328C3.88265 1.73848 4.28132 1.90661 4.57598 2.20128Z" fill="#393939" />
        </svg>
    )
}
