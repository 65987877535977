import { Link } from 'react-scroll'
import './Navigation.css';

function Navigation({ navToggle }) {
  return (
    <nav>
      <Link activeClass="active"
        to="home"
        spy={true}
        smooth={true}
        duration={0}
        delay={0}
        offset={-88}
        onClick={navToggle}
      >
        Home 
      </Link>
      <Link activeClass="active"
        to="overview"
        spy={true}
        smooth={true}
        duration={0}
        delay={0}
        offset={-88}
        onClick={navToggle}
      >
        Overview
      </Link>
      <Link activeClass="active"
        to="boardOfDirectors"
        spy={true}
        smooth={true}
        duration={0}
        delay={0}
        offset={-88}
        onClick={navToggle}
      >
        Board of Directors
      </Link>
      <Link activeClass="active"
        to="managementExecutive"
        spy={true}
        smooth={true}
        duration={0}
        delay={0}
        offset={-88}
        onClick={navToggle}
      >
        Management Executives
      </Link>
      <Link activeClass="active"
        to="contact"
        spy={true}
        smooth={true}
        duration={0}
        delay={0}
        offset={-88}
        onClick={navToggle}
      >
        contact
      </Link>

      {/* <a href="#home" className='active' onClick={navToggle}>home</a>
      <a href="#overview" onClick={navToggle}>Overview</a>
      <a href="#boardOfDirectors" onClick={navToggle}>Board of Directors</a>
      <a href="#managementExecutive" onClick={navToggle}>MANAGEMENT EXECUTIVES</a>
      <a href="#contact" onClick={navToggle}>contact</a> */}
    </nav>
  )
}

export default Navigation