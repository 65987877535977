import { useEffect, useState } from 'react'
import Modal from '../Modal/Modal'
import DirectorCard from './DirectorCard'

import './BoardDirectors.css'
import axios from 'axios'
import { BOARD_DIRECTORS_API } from '../../Utilities/APIs'

function BoardDirectors() {

  const [showModal, setShowModal] = useState(false)
  const [targetID, setTargetID] = useState(null)
  const [directors, setDirectors] = useState(null)
  const [selectedDirector, setSelectedDirector] = useState(null)


  useEffect(() => {
    async function fetchDirectors() {
      const {data} = await axios.get(BOARD_DIRECTORS_API)
      setDirectors(data)
    }
    fetchDirectors()
  }, [])

  useEffect(() => {
    if(directors && targetID) {
        const dir = directors.find(d => d._id === targetID)
        setSelectedDirector(dir)
    }
  }, [directors, targetID])

  return (
    <>
      <section className='board_directors' id='boardOfDirectors'>
        <div className="container">
          <h1 className="sc_title">Board of <span>Directors</span></h1>
          <div className="grid_container">

            {directors?.map(director => (
              <DirectorCard 
                key={director._id}
                imgSrc={director.image}
                name={director.name}
                designation={director.designation}
                targetID={director._id}
                setTargetID={setTargetID}
                setShowModal={setShowModal}
              />
            ))}

            {/* <DirectorCard handler={modalToggle} />
            <DirectorCard />
            <DirectorCard />
            <DirectorCard />
            <DirectorCard />
            <DirectorCard /> */}
          </div>
        </div>
      </section>
      {showModal && <Modal setShowModal={setShowModal} selectedDirector={selectedDirector} />}
    </>
  )
}

export default BoardDirectors