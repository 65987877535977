import { useState, useEffect } from "react";
import axios from "axios";
import { SLIDERS_API } from "../../Utilities/APIs";
import Image from "../Elements/Image";

import mobileImage from '../../Assets/Images/smd-banner-slide-1.png';

import './Banner.css';

function Banner() {

    const [sliders, setSliders] = useState(null);
    useEffect(() => {
        const fetchSliders = async () => {
            const { data } = await axios.get(SLIDERS_API);
            setSliders(data[0]);
        }
        fetchSliders();
    }, []);



    return (
        <section className="banner" id="home">
            <Image imgLink={sliders?.image} imgAlt={sliders?.precedence} />
            <img src={mobileImage} alt="" />
        </section>
    )
}

export default Banner