import { MenuIcon } from '../Elements/Icons';
import Logo from './Logo';
import Navigation from './Navigation';

import React from './Navbar.css';

function Navbar({navToggle}) {
    return (
        <div className='navbar'>
            <div className="container grid_container">
                <div className="grid_item">
                    <Logo />
                </div>
                <div className='grid_item'>
                    <Navigation />
                </div>
                <div className="grid_item">
                    <button type='button' onClick={navToggle}>
                        <MenuIcon />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Navbar