import axios from 'axios';
import { useEffect, useState } from 'react';
// import MEImage1 from '../../Assets/Images/me-1.png';
// import MEImage2 from '../../Assets/Images/me-2.png';
import { MANAGEMENT_EXECUTIVES_API } from '../../Utilities/APIs';

import './ManagementExecutive.css';
// import MgmtExecCard from './MgmtExecCard';
import MgmtITCard from './MgmtITCard';
import MgmtTICard from './MgmtTICard';

function ManagementExecutive() {

    const [executives, setExecutives] = useState(null)
    
    useEffect(() => {
        async function fetchExecutives() {
            const {data} = await axios.get(MANAGEMENT_EXECUTIVES_API)
            setExecutives(data)
        }
        fetchExecutives()
    }, [])

    return (
        <section className='management_executive' id='managementExecutive'>
            <div className="container">
                <h1 className="sc_title">Management <span>Executives</span></h1>
                <div className="grid_container_wrapper">

                    {
                        executives &&
                        <>
                            <MgmtITCard 
                                name={executives[0].name}
                                description={executives[0].description}
                                designation={executives[0].designation}
                                imgSrc={executives[0].image}
                            />
                            <MgmtTICard 
                                name={executives[1].name}
                                description={executives[1].description}
                                designation={executives[1].designation}
                                imgSrc={executives[1].image}
                            />
                        </>
                    }
                    {/* {executives?.map(exec => (
                        <MgmtExecCard 
                            key={exec._id}
                            name={exec.name}
                            description={exec.description}
                            designation={exec.designation}
                            imgSrc={exec.image}
                        />
                    ))} */}

                    {/* <div className="grid_container">
                        <div className="grid_item">
                            <img src={MEImage1} alt="thumb" />
                        </div>
                        <div className="grid_item">
                            <h5>Mr. M Muntasir Alam</h5>
                            <h6>Chief Executive Officer</h6>
                            <p className="text">Experienced senior management executive with excellent commercial strategic vision backed by strong leadership and decision-making skills. He has over 25 years of experience  in  brand  marketing
                                & advertising, sales management, supply chain management & commercial procurement. A versatile professional offering extensive years senior level experience in areas of Strategy Formulation, Commercial Operations Management, Project Management, Business Transformation & Business Development. Having started  his  career  at  Beximco  LPG  in  2016  as the   Chief   Marketing   Officer,
                                Mr. Alam was soon promoted to the position of Chief Commercial Officer and later handed additional responsibilities of managing the overall business operations as well. Subsequently, after formation of the Joint-Venture of Beximco IOC Petroleum & Energy Ltd, Mr. Alam was appointed as the first Chief Executive Officer of the company in 2021.</p>
                        </div>
                    </div>
                    <div className="grid_container">
                        <div className="grid_item">
                            <h5>Mr. Sharad Rakesh</h5>
                            <h6>Chief Financial Officer</h6>
                            <p className="text">With more than 25 years of experience in Tax Planning, Financial Planning & Reporting, Tax Laws, Payroll System, Auditing and Process Engineering, Mr. Sharad Rakesh is a man of multiple talents. He completed his Bachelor of Commerce from Ranchi University and is a member of the Institute of Chartered Accountants of India. After years of experience in Indian Oil Corporation LTD he was appointed as the Chief Financial  Officer  of  Beximco  IOC  Petroleum & Energy Ltd. Mr. Sharad Rakesh is an excellent communicator and great at people management and relationship building. His vast horizon of expertise includes Taxation planning, identifying strengths and weaknesses in financial functions as well as managing systems to effectively improve efficiency and increase cost savings, productivity and bottom lines. He was well appreciated for reengineering the business process of supply location for better Tax Planning resulting in recurring savings to the Indian Oil Corporation. He is also experienced in Payroll Management.</p>
                        </div>
                        <div className="grid_item">
                            <img src={MEImage2} alt="thumb" />
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default ManagementExecutive