import { RiPhoneLine, RiHome8Line, RiMailOpenLine } from 'react-icons/ri';
import bgImage from '../../Assets/Images/contact-us-bg.png';

import './ContactUs.css'

function ContactUs() {
    return (
        <section className='contact_us' style={{backgroundImage: `url(${bgImage})`}} id="contact">
            <div className="container">
                <h1 className="sc_title">Contact <span>Us</span></h1>
                <div className="grid_container">
                    <div className="grid_item">
                        <div className="icon">
                            <RiPhoneLine />
                        </div>
                        <p className="text">+88 02 2222 62496-7</p>
                        <p className="text">16565 / 09609016565</p>
                    </div>
                    <div className="grid_item">
                        <div className="icon">
                            <RiHome8Line />
                        </div>
                        <p className="text">Beximco IOC Petroleum & Energy Limited</p>
                        <p className="text head">Head office</p>
                        <p className="text">Level 11, SAM Tower,
                            Plot 4, Road 22,
                            Gulshan 1, Dhaka 1212, Bangladesh</p>
                    </div>
                    <div className="grid_item">
                        <div className="icon">
                            <RiMailOpenLine />
                        </div>
                        <p className="text">connect@beximco-ioc.com</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactUs