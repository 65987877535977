import LogoImage from '../../Assets/Images/logo.png';

function Logo() {
  return (
    <a href="#home">
        <img className='logo' src={LogoImage} alt="logo" />
    </a>
  )
}

export default Logo