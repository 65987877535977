import { RiArrowRightSLine } from 'react-icons/ri';
import { BASE_URL } from '../../Utilities/APIs';
// import BFDImage from '../../Assets/Images/bfd-1.jpg';

import './DirectorCard.css';

function DirectorCard({ 

    imgSrc, name, designation,
    setShowModal, setTargetID, targetID

 }) {

    return (
      <div className='director_card'>
          <img src={BASE_URL + imgSrc.slice(6, imgSrc.length)} alt="thumb" />
          <div className="content">
              <h5>{name}</h5>
              <h6>{designation}</h6>
              <button type='button' onClick={() =>{
                setShowModal(true)
                setTargetID(targetID)
              }}>
                <RiArrowRightSLine />
              </button>
          </div>
      </div>
    )

}

export default DirectorCard