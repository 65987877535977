import { RiFacebookLine, RiYoutubeFill, RiInstagramLine } from 'react-icons/ri';

import './Footer.css';

function Footer() {
    return (
        <div className="footer">
            <div className="container grid_container">
                <div>
                    <p>© Beximco IOC Petroleum & Energy Limited</p>
                    {/* <br />
                    <a href="/">Terms</a>
                    <a href="/">Privacy</a>
                    <a href="/">Sitemap</a> */}
                </div>
                <div className='socials'>
                    <a href="/"><RiFacebookLine /></a>
                    <a href="/"><RiYoutubeFill /></a>
                    <a href="/"><RiInstagramLine /></a>
                </div>
                <div>
                    <p>Made with 🤍 by Antopolis</p>
                </div>
            </div>
        </div>
    )
}

export default Footer